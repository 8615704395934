import Navbar from "../../components/navbar/navbar";
import Sidebar from "../../components/sidebar/sidebar";
import PanelDatatable from "./paneldatatable";


const PanelList = () => {
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <PanelDatatable />
      </div>
    </div>
  );
};

export default PanelList