export const userColumns = [
    {
        field: "id",
        headerName: "ID",
        width: 70
    },
    {
        field: "panel_code",
        headerName: "Code",
        width: 230,
    },
    {
        field: "section_code",
        headerName: "Section Code",
        width: 230,
    },
    {
        field: "panel_order",
        headerName: "Order",
        width: 70,
    },
    {
        field: "panel_datecreated",
        headerName: "Date Created",
        width: 230
    },
    {
        field: "panel_dateupdated",
        headerName: "Date Updated",
        width: 230
    }
];
