import Navbar from "../../components/navbar/navbar";
import Sidebar from "../../components/sidebar/sidebar";
import "./home.scss";
import { useEffect } from "react";
import axios from "axios";
import { API_URL } from '../../Config'



const Home = () => {

  // useEffect(() => {
  //     axios.get(API_URL + 'main').then((response) => {
  //       console.log(response.data.result)
  //     });
  // }, []);

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        Test
      </div>
    </div>
  );
};

export default Home