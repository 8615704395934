import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns } from "./tablecolumns";
import { json, Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import axios from 'axios';
import { display } from "@mui/system";
import { API_URL } from "../../Config";
// import { userColumns, userRows } from "../../datatablesource";

const SectionDatatable = () => {
    const [data, setData] = useState([]);

    const handleDelete = (id) => {
        axios.post(API_URL + 'section/delete/'+ id).then((response) => {
            const result = response.data;
            if(result.success == true){
                alert('Section Deleted!')
                setData(data.filter((item) => item.id !== id));
            }
        });
    };

    useEffect(() => {
        axios.get(API_URL + 'section').then((response) => {
            const result = response.data.result;
            setData(result);
        });
    }, []);


    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <Link to={"/sections/form/" + params.row.id} style={{ textDecoration: "none" }}>
                            <div className="viewButton">View</div>
                        </Link>
                        <div
                            className="deleteButton"
                            onClick={() => handleDelete(params.row.id)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];


    return (
        <div className="datatable">
            
            <div className="datatableTitle">
                Sections
                <Link to="/sections/form" className="link">
                    Add New
                </Link>
            </div>
             <DataGrid
                className="datagrid"
                rows={data}
                columns={userColumns.concat(actionColumn)}
                pageSize={10}
                rowsPerPageOptions={[10]}
            />
        </div>

    );
};

export default SectionDatatable;