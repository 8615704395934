import { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";


import Navbar from "../../components/navbar/navbar";
import Sidebar from "../../components/sidebar/sidebar";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';
import { SignalCellularNullSharp } from "@mui/icons-material";
import { API_URL } from "../../Config";



const PanelForm = () => {
    // const [rows, setRows] = useState(testTable)
    const navigate = useNavigate();
    const [oType, setOType] = useState()
    const [form, setForm] = useState('Add') //add or update
    const [options, setOptions] = useState([])
    const [formData, setFormData] = useState({
        code: '',
        sectionCode: '',
        className: '',
        style: '',
        orderNo: 0
    })

    const { id } = useParams();

    useEffect(() => {
        if (id) {
            setForm('Update')
            axios.get( API_URL +'panel/' + id).then((response) => {
                const result = response.data.result;
                console.log(result)
                setOType(result[0].section_code)
                setFormData({
                    code: result[0].panel_code,
                    sectionCode: result[0].section_code,
                    className: result[0].panel_classname,
                    style: result[0].panel_dynamicstyle,
                    orderNo: result[0].panel_order
                })
            });

        }
        else {
            setForm('Add')
        }

        axios.get(API_URL + 'section').then((response) => {
            const result = response.data.result;
            setOptions(result);
        });

    }, []);


    const handleSubmit = (e) => {
        e.preventDefault();
        if (form === 'Add') {
            console.log(formData);
            axios.post(API_URL +'panel/new',
                {
                    formData
                })
                .then((response) => {
                    if (response.data.success === true) {
                        alert('Succesfully added')
                    }
                    else {
                        alert('Something went wrong, please contact admin')
                    }
                    navigate('/panels')
                });
        }
        if (form === 'Update') {
            console.log(formData);
            axios.post(API_URL + 'panel/update/' + id,
                {
                    formData
                })
                .then((response) => {
                    if (response.data.success === true) {
                        alert('Succesfully updated')
                    }
                    else {
                        alert('Something went wrong, please contact admin')
                    }
                    navigate('/panels')
                });
        }
    }


    // const selectOption = () => {
    //     console.log(options)
    // }

    return (
        <div className="home">
            <Sidebar />
            <div className="homeContainer">
                <Navbar />
                <Card style={{ margin: "20px" }}>
                    <Card.Body>
                        <h2>{form} Panel</h2>
                        <br></br>
                        <Form onSubmit={handleSubmit}>
                            <div className="row">
                                <Form.Group className="col-md-3" controlId="objectCode">
                                    <Form.Label>Panel Code</Form.Label>
                                    <Form.Control value={formData.code} onChange={(e) => setFormData({ ...formData, code: e.target.value })} type="text" placeholder="" />
                                </Form.Group>
                                <Form.Group className="col-md-3" controlId="objectCode">
                                    <Form.Label>Section</Form.Label>
                                    <select className="form-select" defaultValue={oType} aria-label="Default select example"
                                        onChange={(e) => setFormData({ ...formData, sectionCode: e.target.value })} >
                                        <option>--Select--</option>
                                        {options?.map(o =>
                                            <option value={o.section_code}>{o.section_code}</option>
                                        )}
                                    </select>
                                </Form.Group>
                                <Form.Group className="col-md-3" controlId="objectName">
                                    <Form.Label>Panel Classname</Form.Label>
                                    <Form.Control value={formData.className} onChange={(e) => setFormData({ ...formData, className: e.target.value })} type="text" placeholder="Type Object Name" />
                                </Form.Group>
                                <Form.Group className="col-md-4" controlId="objectStyle">
                                    <Form.Label>Panel Dynamic Style</Form.Label>
                                    <Form.Control value={formData.style} onChange={(e) => setFormData({ ...formData, style: e.target.value })} type="text" />
                                </Form.Group>
                                <Form.Group className="col-md-2" controlId="objectStyle">
                                    <Form.Label>Order #</Form.Label>
                                    <Form.Control value={formData.orderNo} onChange={(e) => setFormData({ ...formData, orderNo: e.target.value })} type="text" />
                                </Form.Group>
                            </div>
                            <br></br>
                            <br />
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>

            </div>
        </div>
    );
};

export default PanelForm