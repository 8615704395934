import Navbar from "../../components/navbar/navbar";
import Sidebar from "../../components/sidebar/sidebar";
import SubPanelDatatable from "./subpaneldatatable";


const SubPanelList = () => {
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <SubPanelDatatable />
      </div>
    </div>
  );
};

export default SubPanelList