import { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";

import Navbar from "../../components/navbar/navbar";
import Sidebar from "../../components/sidebar/sidebar";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';
import ChildrenDataTable from './childrendatatable'
import SideNavChildDT from './SideNavChildDT'
import { API_URL } from "../../Config";

const ObjectForm = () => {
    // const [rows, setRows] = useState(testTable)
    const navigate = useNavigate();
    const [oType, setOType] = useState()
    const [form, setForm] = useState('Add') //add or update
    const [options, setOptions] = useState([])
    const [insertedId, setId] = useState(0)
    const [formData, setFormData] = useState({
        code: '',
        name: '',
        type: '',
        subPanelCode: '',
        className: '',
        text: '',
        style: '',
        src: '',
        order: 0
    })
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            setForm('Update')
            axios.get(API_URL + 'object/' + id).then((response) => {
                const result = response.data.result;
                console.log(result)
                setOType(result[0].object_type)
                setFormData({
                    code: result[0].object_code,
                    name: result[0].object_name,
                    type: result[0].object_type,
                    subPanelCode: result[0].subpanel_code,
                    className: result[0].object_classname,
                    text: result[0].object_text,
                    style: result[0].object_dynamicstyle,
                    src: result[0].object_src,
                    order: result[0].object_order
                })
                console.log(formData.type)
            });

        }
        else {
            setForm('Add')
        }

        axios.get(API_URL + 'subpanel').then((response) => {
            const result = response.data.result;
            setOptions(result);
        });

    }, []);

    const handleTypeChange = (e) => {
        setOType(e)
        setFormData({ ...formData, type: e })
    }

    const sideNavStateRef = useRef();
    const renderChildren = (type) => {
        switch (type) {
            case 'sideNav':
                return <SideNavChildDT ref={sideNavStateRef} />
            default:
                break;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (form === 'Add') {
            console.log(formData)
            axios.post(API_URL + 'object/new',
                {
                    formData
                })
                .then((response) => {
                    if (response.data.success === true) {
                        switch (oType) {
                            case 'sideNav':
                                const sideNavState = sideNavStateRef.current.getRowsData();
                                axios.post(API_URL + 'object/sidenavchildren/new/' + response.data.result.insertId,
                                    {
                                        sideNavState
                                    })
                                    .then((response) => {
                                        if (response.data.success === true) {
                                            alert('Succesfully added')
                                        }
                                        else {
                                            alert('Something went wrong, please contact admin')
                                        }
                                        navigate('/objects')
                                    });
                            default:
                                alert('Succesfully added')
                                navigate('/objects')
                                break;
                        }
                    }
                    else {
                        alert('Something went wrong, please contact admin')
                    }
                    // navigate('/panels')
                });


        }
        if (form === 'Update') {
            axios.post(API_URL + 'object/update/' + id,
                {
                    formData
                })
                .then((response) => {
                    if (response.data.success === true) {
                        switch (oType) {
                            case 'sideNav':
                                const sideNavState = sideNavStateRef.current.getRowsData();
                                axios.post(API_URL + 'object/sidenavchildren/delete/' + id)
                                    .then((response) => {
                                        if (response.data.success === true) {
                                            axios.post(API_URL + '/object/sidenavchildren/new/' + id,
                                                {
                                                    sideNavState
                                                })
                                                .then((response) => {
                                                    if (response.data.success === true) {
                                                        alert('Succesfully added')
                                                    }
                                                    else {
                                                        alert('Something went wrong, please contact admin')
                                                    }
                                                    navigate('/objects')
                                                });
                                        }
                                        else {
                                            alert('Something went wrong, please contact admin')
                                        }
                                    });
                            default:
                                alert('Succesfully added')
                                navigate('/objects')
                                break;
                        }
                    }
                    else {
                        alert('Something went wrong, please contact admin')
                    }
                    // navigate('/panels')
                });
        }
    }


    return (
        <div className="home">
            <Sidebar />
            <div className="homeContainer">
                <Navbar />
                <Card style={{ margin: "20px" }}>
                    <Card.Body>
                        <h2>{form} Object</h2>
                        <br></br>
                        <Form onSubmit={handleSubmit}>
                            <div className="row">
                                <Form.Group className="col-md-3" controlId="objectCode">
                                    <Form.Label>Object Code</Form.Label>
                                    <Form.Control type="text" value={formData.code} placeholder="" onChange={(e) => setFormData({ ...formData, code: e.target.value })} />
                                </Form.Group>
                                <Form.Group className="col-md-3" controlId="objectName">
                                    <Form.Label>Object Name</Form.Label>
                                    <Form.Control type="text" value={formData.name} placeholder="Type Object Name" onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                                </Form.Group>
                                <Form.Group className="col-md-3" controlId="objectType">
                                    <Form.Label>Object Type</Form.Label>
                                    <select class="form-select" aria-label="Default select example" onChange={e => handleTypeChange(e.target.value)}>
                                        <option>--Select--</option>
                                        <option value="sideNav" selected={formData.type === 'sideNav'}>Side Nav</option>
                                        <option value="img" selected={formData.type === 'img'}>Image</option>
                                        <option value="text" selected={formData.type === 'text'}>Text</option>
                                        <option value="button" selected={formData.type === 'button'}>Button</option>
                                        <option value="accordion1" selected={formData.type === 'accordion1'}>Accordion (Price)</option>
                                        <option value="accordion2" selected={formData.type === 'accordion2'}>Accordion (Showcase)</option>
                                    </select>
                                </Form.Group>
                                <Form.Group className="col-md-3" controlId="objectName">
                                    <Form.Label>Subpanel Code</Form.Label>
                                    <select class="form-select" aria-label="Default select example" onChange={(e) => setFormData({ ...formData, subPanelCode: e.target.value })}>
                                        <option>--Select--</option>
                                        {options?.map(o =>
                                            <option value={o.subpanel_code} selected={formData.subPanelCode === o.subpanel_code}>{o.subpanel_code}</option>
                                        )}
                                    </select>
                                </Form.Group>
                            </div>
                            <br></br>
                            <div className="row">
                                <Form.Group className="col-md-3" controlId="className">
                                    <Form.Label>Class Name</Form.Label>
                                    <Form.Control type="text" value={formData.className} placeholder="For special css" onChange={(e) => setFormData({ ...formData, className: e.target.value })} />
                                </Form.Group>
                                <Form.Group className="col-md-2" controlId="objectText">
                                    <Form.Label>Object Text (For Text Object)</Form.Label>
                                    <Form.Control type="text" value={formData.text} onChange={(e) => setFormData({ ...formData, text: e.target.value })} />
                                </Form.Group>
                                <Form.Group className="col-md-3" controlId="objectStyle">
                                    <Form.Label>Object Dynamic Style</Form.Label>
                                    <Form.Control type="text" value={formData.style} onChange={(e) => setFormData({ ...formData, style: e.target.value })} />
                                </Form.Group>
                                <Form.Group className="col-md-2" controlId="objectStyle">
                                    <Form.Label>Object Source (Image)</Form.Label>
                                    <Form.Control type="text" value={formData.src} onChange={(e) => setFormData({ ...formData, src: e.target.value })} />
                                </Form.Group>
                                <Form.Group className="col-md-2" controlId="orderNo">
                                    <Form.Label>Order #</Form.Label>
                                    <Form.Control type="text" value={formData.order} onChange={(e) => setFormData({ ...formData, order: e.target.value })} />
                                </Form.Group>
                            </div>
                            <br></br>
                            <div className="row">
                                {renderChildren(oType)}
                            </div>
                            <br />
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>

            </div>
        </div>
    );
};

export default ObjectForm