import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../Config";

function TableRows({ rowsData, deleteTableRows, handleChange }) {
    return (
        rowsData.map((data, index) => {
            const { Value, ClassName, Style, Order } = data;
            return (
                <tr key={index}>
                    <td>
                        <input type="text" value={Value} onChange={(evnt) => (handleChange(index, evnt))} name="Value" className="form-control" />
                    </td>
                    <td><input type="text" value={ClassName} onChange={(evnt) => (handleChange(index, evnt))} name="ClassName" className="form-control" /> </td>
                    <td><input type="text" value={Style} onChange={(evnt) => (handleChange(index, evnt))} name="Style" className="form-control" /> </td>
                    <td><input type="text" value={Order} onChange={(evnt) => (handleChange(index, evnt))} name="Order" className="form-control" /> </td>
                    <td><button className="btn btn-outline-danger" onClick={() => (deleteTableRows(index))}>Delete</button></td>
                </tr>
            )
        })
    )
}

const SideNavChildDT = forwardRef((props, _ref) => {
    const [rowsData, setRowsData] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            axios.get(API_URL + 'object/sidenavchildren/' + id).then((response) => {
                const result = response.data.result;
                setRowsData(result)
            });
        }
    }, []);

    useImperativeHandle(_ref, () => ({
        getRowsData: ()  =>{
            return rowsData
        }
    }))

    const addTableRows = (e) => {
        e.preventDefault();
        const rowsInput = {
            Value: '',
            ClassName: '',
            Style: '',
            Order: 0
        }
        setRowsData([...rowsData, rowsInput])

    }
    const deleteTableRows = (index) => {
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
    }

    const handleChange = (index, evnt) => {

        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        setRowsData(rowsInput);
    }
    return (

        <div className="container">
            <div className="row">
                <label>Side Nav Children</label><hr />
                <div className="offset-1 col-md-10">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Value</th>
                                <th>Class Name</th>
                                <th>Style</th>
                                <th>Order #</th>
                                <th><button className="btn btn-primary" onClick={addTableRows} >Add New</button></th>
                            </tr>
                        </thead>
                        <tbody>
                            <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} />
                        </tbody>
                    </table>
                </div>
                <div className="col-sm-4">
                </div>
            </div>
        </div>
    )
})
export default SideNavChildDT