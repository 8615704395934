import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns} from "./tablecolumns";
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from "../../Config";

const ObjectDatatable = () => {
    const [data, setData] = useState([]);
    const [post, setPost] = useState(null); //for api calls

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    
    useEffect(() => {
        axios.get(API_URL + 'object').then((response) => {
            const result = response.data.result;
            console.log(result)
            setData(result);
        });
    }, []);


    const handleDelete = (id) => {
        axios.post(API_URL + 'object/delete/'+ id).then((response) => {
            const result = response.data;
            if(result.success == true){
                alert('Section Deleted!')
                setData(data.filter((item) => item.id !== id));
            }
        });
    };

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <Link to={"/objects/form/" + params.row.id} style={{ textDecoration: "none" }}>
                            <div className="viewButton">View</div>
                        </Link>
                        <div
                            className="deleteButton"
                            onClick={() => handleDelete(params.row.id)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <div className="datatable">
            <div className="datatableTitle">
                Objects
                <Link to="/objects/form" className="link">
                    Add New
                </Link>
            </div>
            <DataGrid
                className="datagrid"
                rows={data}
                columns={userColumns.concat(actionColumn)}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection
            />
        </div>

    );
};

export default ObjectDatatable;