export const userColumns = [
    {
        field: "id",
        headerName: "ID",
        width: 70
    },
    {
        field: "object_code",
        headerName: "Code",
        width: 230,
    },
    {
        field: "object_type",
        headerName: "Type",
        width: 230,
    },
    {
        field: "subpanel_code",
        headerName: "Sub Panel Code",
        width: 230,
    },
    {
        field: "object_datecreated",
        headerName: "Date Created",
        width: 230
    },
    {
        field: "object_dateupdated",
        headerName: "Date Updated",
        width: 230
    }
];
