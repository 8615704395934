import Home from './pages/home/home'
import List from './pages/list/list';
import Single from './pages/single/single';
import New from './pages/new/new';

import ObjectList from './pages/object/objectlist';
import ObjectForm from './pages/object/objectform';

import SectionList from './pages/section/sectionlist';
import SectionForm from './pages/section/sectionform'

import PanelList from './pages/panel/panellist';
import PanelForm from './pages/panel/panelform';

import SubPanelList from './pages/subpanel/subpanellist';
import SubPanelForm from './pages/subpanel/subpanelform';


import ObjectCreate from './pages/object/objectcreate';

import { BrowserRouter, Routes, Route } from 'react-router-dom'


function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/'>
            <Route index element={<Home />}></Route>
            <Route path='users'>
              <Route index element={<List />}></Route>
              <Route path=':userId' element={<Single />}></Route>
              <Route path='new' element={<New />}></Route>
            </Route>
            <Route path='objects'>
              <Route index element={<ObjectList />}></Route>
              <Route path='form' element={<ObjectForm />}>
                <Route path=':id' element={<ObjectForm />}></Route>
              </Route>
            </Route>
            <Route path='sections'>
              <Route index element={<SectionList />}></Route>
              <Route path='form' element={<SectionForm />}>
                <Route path=':id' element={<SectionForm />}></Route>
              </Route>
              {/* <Route path='new' element={<ObjectCreate/>}></Route> */}
            </Route>
            <Route path='panels'>
              <Route index element={<PanelList />}></Route>
              <Route path='form' element={<PanelForm />}>
                <Route path=':id' element={<PanelForm />}></Route>
              </Route>
              {/* <Route path='new' element={<ObjectCreate/>}></Route> */}
            </Route>
            <Route path='subpanels'>
              <Route index element={<SubPanelList />}></Route>
              <Route path='form' element={<SubPanelForm />}>
                <Route path=':id' element={<SubPanelForm />}></Route>
              </Route>
              {/* <Route path='new' element={<ObjectCreate/>}></Route> */}
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
